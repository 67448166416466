import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import Section from 'containers/section';
import Column from 'containers/column';
import Image from 'components/image';
import Title from 'components/title';
import { theme } from 'constants/theme';
// import FadeUp from 'components/animations/fadeUp';
import { SwiggleIcon } from 'components/icon';
import CenteredImageText from 'containers/centeredImageText';

const ThingsToDo = ({ data }) => (
  <Layout headerTheme="dark">
    <Head pageTitle={data.thingsToDoJson.title} />
    <Section bg="#E3C7B1" type="block">
      <SwiggleIcon />
      <Title as="h2" color={theme.colors.base} align="center" size="large">
        Things to do and places to see
      </Title>
      <Image
        src={data.thingsToDoJson.heroImage.image}
        alt={data.thingsToDoJson.heroImage.title}
      />
    </Section>
    <Section reverseOnMobile columns="1fr 1fr">
      <Column>
        <Image
          src={data.thingsToDoJson.section1.image}
          alt={data.thingsToDoJson.section1.title}
        />
      </Column>
      <Column>
        <Title as="h2" color={theme.colors.base}>
          Our local village ~ Blue Bay + Toowoon Bay
        </Title>
        <div>
          <a
            href="https://www.instagram.com/flourandco_/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Flour and Co.</h6>
          </a>
          <p>Great coffee, bakery + raw treats</p>
          <a
            href="https://www.bayorganics.com.au/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Bay Organics</h6>
          </a>
          <p>Great for smoothie bowls and fresh juices!</p>
          <a
            href="https://www.instagram.com/twobirdsgallerycafe/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Two Birds Gallery Cafe</h6>
          </a>
          <p>Yummy brekky / lunch spot!</p>
          <a
            href="http://www.oceanrestaurant.com.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Ocean Restaurant</h6>
          </a>
          <p>Fine dining lunch / dinner spot with great view</p>
          <a
            href="https://kims.com.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Kims Hideaway Retreat</h6>
          </a>
          <p>
            Beachfront breakfast / dinner. Enjoy the cocktail bar pre-dinner.
            Bookings essential.
          </p>
        </div>
      </Column>
    </Section>
    <Section bg={theme.colors.base} color={theme.colors.bg} columns="1fr 1fr">
      <Column>
        <Title as="h2" color={theme.colors.bg}>
          Out + about in Long Jetty
        </Title>
        <div>
          <p>
            You have to catch a Long Jetty sunset, maybe take a picnic + a
            bottle of wine. Be sure to walk on the longest Jetty in NSW. The
            scenic bicycle + walking track will take you to The Entrance. If
            you’re around on the last Saturday of the month you’ll catch the
            markets along the Long Jetty foreshore.
          </p>
          <Title as="h5" size="small">
            Cafes
          </Title>
          <a
            href="http://theglassonionsociety.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>The Glass Onion Society</h6>
          </a>
          <p>Great coffee, food and vibe!</p>
          <a
            href="http://modernorganic.com.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Modern Organic</h6>
          </a>
          <p>Delicious healthy food options, relaxing courtyard, yoga</p>
          <a
            href="https://www.instagram.com/greentangerinelongjetty"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Green Tangerine</h6>
          </a>
          <p>Bakery, coffee and tasty food!</p>

          <Title as="h5" size="small">
            Bars + Restaurants
          </Title>
          <a
            href="https://www.thesavoylongjetty.com.au/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>The Savoy</h6>
          </a>
          <p>
            Wine bar in a refurbished vintage cinema, pizzas and antipasto
            (check out their socials - you may be able to catch a movie!)
          </p>
          <a
            href="https://hernandoshideawaylj.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Hernando’s Hideaway</h6>
          </a>
          <p>Modern Mexican cuisine. Try the Margaritas! Bookings essential.</p>

          <Title as="h5" size="small">
            Boutiques:
          </Title>
          <a
            href="https://www.instagram.com/shadowbang/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Shadow bang</h6>
          </a>
          <a
            href="https://plainjanesstore.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Plain Janes</h6>
          </a>
          <a
            href="https://www.sadielou.com.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Sadie Lou</h6>
          </a>
          <a
            href="https://www.facebook.com/homebythejetty"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Home By The Jetty</h6>
          </a>
          <p>You’ll also find some cool vintage stores.</p>
        </div>
      </Column>
      <Column>
        <Image
          src={data.thingsToDoJson.section2.image}
          alt={data.thingsToDoJson.section2.title}
        />
      </Column>
    </Section>
    <Section reverseOnMobile columns="1fr 1fr">
      <Column>
        <Image
          src={data.thingsToDoJson.section3.image}
          alt={data.thingsToDoJson.section3.title}
        />
      </Column>
      <Column>
        <Title as="h2" color={theme.colors.base}>
          Exploring by car ~ Central Coast
        </Title>
        <div>
          <h6>The Entrance (Also accessible by foot from the Nest)</h6>
          <ul>
            <li>Gorgeous waterfront</li>
            <li>
              Enjoy the pelican feeding, fish and chips on the grass or a pie
              from Kens Humble Pies
            </li>
            <li>Best dining is Bistro Jinga on the waterfront. BYO wine</li>
            <li>
              Check out Memory Lane, an eclectic vintage store on Coral St.
            </li>
          </ul>

          <h6>Bateau Bay (5 mins drive from the Nest)</h6>
          <ul>
            <li>
              Stunning walking track from Crackneck Lookout to Forresters Beach
              Lookout
            </li>
            <li>Blackbird, fashion + homewares boutique</li>
            <li>Cafe Mocha, great breakfast or lunch spot</li>
          </ul>

          <h6>Foresters Beach Drift Home + Living (10 min drive) </h6>
          <ul>
            <li>a boutique homewares store and cool coffee spot next door.</li>
          </ul>

          <h6>Terrigal (15 min drive)</h6>
          <ul>
            <li>Bustling, beachfront town</li>
            <li>Plenty of top class boutiques, cafes and restaurants</li>
          </ul>

          <h6>Norah Head (15 min drive)</h6>
          <ul>
            <li>
              Plenty of trendy cafes, rock pools and the lighthouse walk and
              lookout.
            </li>
          </ul>

          <h6>Wyong (30 min drive)</h6>
          <ul>
            <li>
              Go west and head towards the Wyong Milk Factory. Loads of things
              to do and see. On the way stop at the Chapman building in Wyong
              and have a Glee coffee and visit Marshmallow and Co. Go for a
              drive through Yarramalong and picnic at the Wattagans Pine Forest.
            </li>
          </ul>
        </div>
      </Column>
    </Section>
    <Section bg={theme.colors.base2} columns="1fr 1fr">
      <Column>
        <Title as="h2" color={theme.colors.base}>
          Day Trips
        </Title>
        <div>
          <h6>Patonga (1hr drive)</h6>
          <ul>
            <li>Quaint old fishing village</li>
            <li>‘The Boathouse’ for breakfast, lunch or dinner. Great view!</li>
            <li>Catch the ferry from Patonga to Palm Beach</li>
          </ul>

          <h6>Hunter Valley Wine Region (approx 1 hour away) </h6>
          <ul>
            <li>
              Brekky at Cafe Enzo and Lunch at Bistro Molines would be our pick
            </li>
            <li>Plenty of amazing cellar doors for wine tasting</li>
            <li>
              Newcastle is also about an hour away from our Nest and a very
              short drive from the Hunter. The beaches are stunning as are the
              cafes and restaurants. Honeysuckle Wharf overlooking the harbor is
              our favorite place to dine. A stroll up to Darby Street for coffee
              and shopping is a must!
            </li>
          </ul>

          <h6>Newcastle (approx 1 hour away)</h6>
          <ul>
            <li>Stunning beaches</li>
            <li>Trendy cafes + restaurants</li>
            <li>
              Honeysuckle Wharf overlooking the Harbour is a lovely place for
              wining + dining
            </li>
            <li>Darby St is a must!</li>
          </ul>

          <h6>Sydney (1.5 hour drive)</h6>
          <ul>
            <li>
              We all know or have heard about the cool iconic places to see
              surrounding our famous harbour
            </li>
            <li>
              The closest train station is Tuggerah and there is a bus stop
              opposite the Toowoon Bay Shops.
            </li>
          </ul>

          <p>
            For big department stores + shopping centres visit Erina Fair or
            Westfield Tuggerah - both approximately 25 minutes from the Nest.
          </p>
        </div>
      </Column>
      <Column>
        <Image
          src={data.thingsToDoJson.section4.image}
          alt={data.thingsToDoJson.section4.title}
        />
      </Column>
    </Section>
    <Section bg={theme.colors.base} height="100vh">
      <CenteredImageText>
        <Link to="/bookings">
          <Title as="h3" size="giant" color={theme.colors.bg} align="center">
            Make a reservation
          </Title>
        </Link>

        <Image
          src={data.thingsToDoJson.section5.image}
          alt={data.thingsToDoJson.section5.title}
        />
      </CenteredImageText>
    </Section>
  </Layout>
);

ThingsToDo.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ThingsToDo;

export const query = graphql`
  query ThingsToDoQuery {
    thingsToDoJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
      }
      section1 {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        body {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
      }
      section2 {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        body {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
      }
      section3 {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
      }
      section4 {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
      }
      section5 {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
      }
    }
  }
`;
